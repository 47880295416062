<template>
  <div class="content">
    <div class="Preview-Main">
      <div class="Preview-Parent" id="zkviewParent" @click="itemClick()">
        <div
          style="height: 100%; width: 100%; overflow: hidden"
          id="zkScrollBox"
        >
          <div
            class="zk-view-preview animate__animated animate__null animate__null"
            id="zkviewContent"
          ></div>
        </div>
      </div>
    </div>
    <!-- 虚拟dom -->
    <div
      id="zk_render_dom"
      style="position: fixed; left: 10000px; top: 10000px; overflow: hidden"
    ></div>
  </div>
</template>
<script>
export default {
  name: "equipment",
  props: {},
  methods: {
    itemClick() {
      this.$router.push("/tupuDiagnoise-2");
    },
  },
  mounted() {
    window.zkViewStaticRootPath = "./topo";
    window.previewOpen('7d026380-b9a3-4ab0-a401-eb78bdc01cc8')
  },
};
</script>

<style lang="scss" scoped>

.content {
  width: 100%;
  height: calc(100vh - 140px);
  color: #ffffff;
  position: relative;
  margin-top: 0;
}
.Preview-Main {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Preview-Parent {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 33, 65, 1);
}

.zk-view-preview {
  position: relative;
  overflow: hidden;
}

</style>